import DF from './define'


// APP配置
const config = {
    v: "1.0.0",
    isTest: true,
    DF,
    get host() {
        // return "https://jhjapptest.rongxiangipo.cn/pc";
        return "https://www.rongxiangindustry.com/pc";
        // return "http://cmapi.trjcn.test/pc";
        // return "http://192.168.10.142:9999/pc";
        // return "/pc"
    },
    get bdKeyword() {
        // return "https://jhjoss.rongxiangipo.cn/dev/bigdata/keyword.txt";
        return "https://jhjoss.rongxiangipo.cn/prod/bigdata/keyword.txt";
    }
}
if (window) {
    window.rxConfig = config;
    console.log(config.v)
}

export default config;